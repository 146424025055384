// https://nextjs.org/docs/app/api-reference/file-conventions/error

'use client';

import { useEffect } from 'react';
import { useRouter } from 'next/navigation';
import { ErrorPageSkeleton } from '../ErrorPageSkeleton/ErrorPageSkeleton';
import {
  dataDogErrorHelperRoot,
  DatadogErrorLevels,
} from '@bts-web/utils-next-infrastructure';
import { isDevelopmentEnv } from '@bts-web/utils-functions';

interface ErrorBoundaryProps {
  error: Error & { digest?: string };
  isTopLevel?: boolean;
}

export function ErrorBoundary({ error, isTopLevel }: ErrorBoundaryProps) {
  const router = useRouter();

  useEffect(() => {
    console.log(error); // debugging purposes

    dataDogErrorHelperRoot(new Error(JSON.stringify(error)), {
      environment: process.env.NEXT_PUBLIC_DATADOG_ENV,
      severity: isTopLevel
        ? DatadogErrorLevels.CRITICAL
        : DatadogErrorLevels.HIGH,
    });

    // this functionality can be extended to change error route based on error type (BE error, timeout, no network)
    if (!isDevelopmentEnv()) {
      router.push('/error/server');
    }
  }, []); // eslint-disable-line

  return <ErrorPageSkeleton />;
}
